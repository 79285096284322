import "./styles/main.scss";
import Swiper from "swiper";
import IMask from "imask";
const ScrollMagic = require("ScrollMagic");
require("debug.addIndicators");
import selectize from "selectize";
import "selectize/dist/css/selectize.css";
window.$ = $;

if ($("select").length) {
  $("select").selectize();
}

if ($(".detail__inner-about .block").length) {
  $(".detail__inner-about .block").click(function () {
    $(".detail__inner").toggleClass("active");
  });
}

if ($(".share").length) {
  $(".share__button").click(function () {
    $(".share").toggleClass("active");
  });
}
if ($(".home-works").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".home-works",
    duration: "90%",
  })
    .setClassToggle(".home-works", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".works-detail-entry").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".works-detail-entry",
  })
    .setClassToggle(".works-detail-entry", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".works-detail-participants").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".works-detail-participants",
    duration: "100%",
  })
    .setClassToggle(".works-detail-participants", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".works-detail-participants ul").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".works-detail-participants ul",
    duration: "50%",
  })
    .setClassToggle(".works-detail-participants ul", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".foundation-team").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".foundation-team",
    duration: "160%",
  })
    .setClassToggle(".foundation-team", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".foundation-gallery").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".foundation-gallery",
    duration: "100%",
  })
    .setClassToggle(".foundation-gallery", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".staging-kuzguncuk").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".staging-kuzguncuk",
    duration: "100%",
  })
    .setClassToggle(".staging-kuzguncuk", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".works").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".works",
  })
    .setClassToggle(".works", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}
if ($(".activity").length) {
  var controller = new ScrollMagic.Controller();

  var ourScne = new ScrollMagic.Scene({
    triggerElement: ".activity",
  })
    .setClassToggle(".activity", "effect")
    // .addIndicators({
    //   name: "fade scene",
    //   colorTrigger: "black",
    //   colorStart: "#75C695",
    // })
    .addTo(controller);
}

$(window).on("load", function () {
  $(".loader-wrapper").addClass("loaded");
  setTimeout(function () {
    $(".loader-wrapper").remove();
  }, 1000);
  $("body").delay(1000).css({ overflow: "unset" });
});

$(".menu-block").click(function (e) {
  $(".menu-block").toggleClass("open");

  $("html").toggleClass("menu-active");
  $("body").toggleClass("menu-active");

  if ($(".menu-block").hasClass("open")) {
    $(".menu-block strong").text("Kapat");
  } else {
    $(".menu-block strong").text("Menu");
  }

  $(".header").toggleClass("menu-active");

  e.preventDefault();
});

$(".ghost-search__icon").click(function () {
  $(".ghost-search").toggleClass("active");
});

var seminarSliderThumb = new Swiper(".seminar-slider-thumb", {
  direction: "vertical",
  slidesPerView: 5,
  spaceBetween: 0,
  slideToClickedSlide: true,
  allowTouchMove: false,
  speed: 1000,
});
var seminarSlider = new Swiper(".seminar-slider", {
  direction: "horizontal",
  slidesPerView: 1,
  speed: 1000,
  thumbs: {
    swiper: seminarSliderThumb,
  },
  autoplay: {
    delay: 3000,
  },
  breakpoints: {
    768: {
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1,
      speed: 1000,
      thumbs: {
        swiper: seminarSliderThumb,
      },
    },
  },
});

var homeWorksSlider = new Swiper(".home-works__slider", {
  slidesPerView: "auto",
  freeMode: true,
  spaceBetween: 22,
  breakpoints: {
    992: {
      slidesPerView: 4,
      spaceBetween: 22,
      direction: "horizontal",
      autoplay: {
        delay: 6000,
      },
    },
  },
});

var eventMemoriesSlider = new Swiper(".event-memories__slider", {
  slidesPerView: "auto",
  spaceBetween: 22,
  direction: "horizontal",
  autoplay: {
    delay: 6000,
  },
});

var abpSlider = new Swiper(".abp-slider .swiper-container", {
  slidesPerView: 1,
  loop: true,
  navigation: {
    nextEl: ".abp-slider-navigation-button--next",
    prevEl: ".abp-slider-navigation-button--prev",
  },
});

$("body.home-page .event-memories__top-menu a").click(function (e) {
  e.preventDefault();
  var dataId = $(this).data("id");
  $(this).parent().addClass("active").siblings().removeClass("active");

  $(".event-memories__slider .swiper-slide").removeClass("swiper-slide").hide();
  $(`.event-memories__slider [data-id=${dataId}]`)
    .addClass("swiper-slide")
    .show();
  eventMemoriesSlider.update();
  eventMemoriesSlider.slideTo(0);
});

var teamSlider = new Swiper(".foundation-team__slider", {
  slidesPerView: "auto",
  freeMode: "true",
  spaceBetween: 20,
  breakpoints: {
    992: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
});

var foundationGalleryThumb = new Swiper(".foundation-gallery-thumb", {
  slidesPerView: 3,
  spaceBetween: 0,
  speed: 1000,
  slideToClickedSlide: true,
  loop: true,
  centeredSlides: true,
  autoplay: {
    delay: 6000,
  },
});
var foundationGallery = new Swiper(".foundation-gallery__slider", {
  slidesPerView: 1,
  loopAdditionalSlides: 1,
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 6000,
  },
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  },
  thumbs: {
    swiper: foundationGalleryThumb,
  },
});
var designSlider = new Swiper(".staging-design .swiper-container", {
  slidesPerView: 1,
  direction: "vertical",
  speed: 1000,
});

if ($(".home-entry__accordion-item").length) {
  (function () {
    var interval = 3000; // 3 seconds
    var items = $(".home-entry__accordion-item");
    var itemsLength = items.length;
    var currentIndex = itemsLength - 1;
    var theIntervalReference = 0;

    function goToIndex(index) {
      $(".home-entry__accordion-item").stop().removeClass("active");
      $(items[index]).addClass("active");
      currentIndex = index;
    }

    function createInterval() {
      return setInterval(function () {
        var nextIndex = (currentIndex - 1 + itemsLength) % itemsLength;
        goToIndex(nextIndex);
      }, interval);
    }

    theIntervalReference = createInterval();

    $(".home-entry__accordion-item-inner")
      .mouseenter(function () {
        clearInterval(theIntervalReference);
      })
      .mouseleave(function () {
        theIntervalReference = createInterval();
      });

    $(".home-entry__accordion-item-image").click(function (e) {
      e.preventDefault();
      goToIndex(items.index($(this).parent()));
    });
  })();
}

if ($("[apply-to-the-open-call]").length) {
  $("[apply-to-the-open-call]").on("click", function (e) {
    if ($("body").hasClass("show-modal")) {
      $("body").removeClass("show-modal");
      $("html").removeClass("show-modal");
    } else {
      $("body").addClass("show-modal");
      $("html").addClass("show-modal");
    }

    e.preventDefault();
  });

  $(".abp-modal-inner-close").on("click", function (e) {
    if ($("body").hasClass("show-modal")) {
      $("body").removeClass("show-modal");
      $("html").removeClass("show-modal");
    } else {
      $("body").addClass("show-modal");
      $("html").addClass("show-modal");
    }

    e.preventDefault();
  });
}

if ($(".abp-inner__menu").length) {
  $(".abp-inner__menu ul li a").on("click", function (e) {
    $(".abp-inner__menu ul li").removeClass("active");
    $(this).parent().addClass("active");
  });
}

let mainNavLinks = document.querySelectorAll("nav.abp-inner__menu ul li a");
let mainSections = document.querySelectorAll(".abp-inner__wrapper-block");

let lastId;
let cur = [];


window.addEventListener("scroll", event => {
  let fromTop = window.scrollY;

  mainNavLinks.forEach(link => {
    let section = document.querySelector(link.hash);

    if (
      section.offsetTop - 160 <= fromTop &&
      section.offsetTop + section.offsetHeight - 160  > fromTop
    ) {
      link.parentElement.classList.add("active");
    } else {
      link.parentElement.classList.remove("active");
    }
  });
});

$(document).ready(function(){
  $('[type="file"]').change(function(){
    var fileName = this.files[0].name;
    var fileSize = this.files[0].size / 1024 / 1024;

    $(this).parent().parent().removeClass("form-item--error");
    $(this).parent().siblings(".error-message").hide();
    $(this).parent().parent().removeClass("upload-item--selected");

    if(fileSize > 5){
      $(this).parent().parent().addClass("form-item--error");
      $(this).parent().siblings(".error-message").show();
      $(this).val("");
      return
    }
    $(this).parent().parent().addClass("upload-item--selected");
  })

  // File input
  $('.pick-item input[type=radio][name=selected_craft]').change(function () {
    $(".upload-item").removeClass("upload-item--disabled");
    $("#upload-design-proposal").removeAttr("disabled");
  });

  // Input Mask
  IMask(
    document.getElementById('date-overwrite-mask'),
    {
      mask: Date,
      lazy: true
    }
  );

  // IMask(
  //   document.getElementById('phone-mask'),
  //   {
  //     mask: '+{9\\0} (000) 000 00 00',
  //     lazy: false
  //   }
  // );
})